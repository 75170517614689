// require stylesheets for prod build
exports.onClientEntry = () => {
  // fonts
  require('./src/style-utilities/fonts.css');
  // bootstrap
  require('bootstrap/dist/css/bootstrap.min.css');
  // global styles
  require('./src/components/layout.css');
  // slick carousel styles
  require('slick-carousel/slick/slick.css');
  require('slick-carousel/slick/slick-theme.css');
};
